<template>
    <div class="relative text-left w-full">
      <button
        type="button"
        @click="toggleDropdown"
        class="inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      >
        {{ selectedOptionText }}
        <svg class="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>
  
      <!-- Dropdown Menu -->
      <div v-if="isOpen" class="absolute right-0 z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-1">
          <label
            v-for="option in statusOptions"
            :key="option.value"
            class="flex items-center p-2 cursor-pointer hover:bg-gray-100"
          >
            <input
              type="radio"
              :value="option.value"
              v-model="selectedContentStatus"
              @change="handleChange"
              class="form-radio h-4 w-4 text-blue-600"
            />
            <span class="ml-2 text-gray-700">{{ option.text }}</span>
          </label>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContentTypeSelector',
    props: {
      value: [String,Number,Boolean],
      defaultText: {
      type: String,
      default: 'Select Content Type'
    },
      options: Array
    },
    data() {
      return {
        isOpen: false,
        selectedContentStatus: this.value || '',
        statusOptions: this.options
      };
    },
    computed: {
      selectedOptionText() {
        const selectedOption = this.statusOptions.find(option => option.value === this.selectedContentStatus);
        return selectedOption ? selectedOption.text : this.defaultText;
      }
    },
    mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      handleChange() {
        this.isOpen = false;
        this.$emit('input', this.selectedContentStatus);
      },
      handleClickOutside(event) {
      if (this.isOpen && !this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    }
      
    },
    
    watch: {
      value(newValue) {
        this.selectedContentStatus = newValue;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Additional styling if needed */
  </style>
  